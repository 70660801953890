import { extendTheme, StyleFunctionProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

/**
 * What can I customize?
 *
 * @see https://chakra-ui.com/docs/styled-system/customize-theme
 */
const theme = {
  useSystemColorMode: true,
  components: {
    Link: {
      variants: {
        // you can name it whatever you want
        primary: ({ colorScheme = 'blue', ...props }: StyleFunctionProps) => ({
          color: mode(`${colorScheme}.600`, `${colorScheme}.400`)(props),
          _hover: {
            color: mode(`${colorScheme}.500`, `${colorScheme}.300`)(props),
          },
        }),
      },
      defaultProps: {
        // you can name it whatever you want
        variant: 'primary',
      },
    },
  },
};

/**
 * What is this file for?
 *
 * @see https://www.gatsbyjs.com/docs/how-to/plugins-and-themes/shadowing
 */
export default extendTheme(theme);
